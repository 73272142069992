<template>
  <div class="page flex-col">
    <div class="group_1 flex-col">
      <div class="group_37 flex-col">
        <div class="section_17 flex-row">
          <img
            class="image_19"
            referrerpolicy="no-referrer"
            src="../../assets/img/home/2.png"
          />
          <span class="text_56 pornter" @click="$router.go(0)">首页</span>
          <span class="text_57 pornter" @click="goPositon('#ptzs')"
            >平台展示</span
          >
          <span class="text_58 pornter" @click="goPositon('#tcjs')"
            >同城寄送</span
          >
          <span class="text_59 pornter" @click="goPositon('#fgyc')"
            >快递云仓</span
          >
          <span class="text_60 pornter" @click="goPositon('#gyfg')"
            >聚合快递</span
          >
          <span class="text_61 pornter" @click="goPositon('#hyxw')"
            >行业新闻</span
          >
          <span class="text_62 pornter" @click="goPositon('#cstd')"
            >创始团队</span
          >
          <!-- <div class="text-wrapper_23 flex-row" @click="login"> -->
          <!-- <span class="text_63">注册</span> -->
          <!-- <div class="text_64">登录</div> -->
          <!-- <a class="text_63" style="color: #fff;" href="http://vip-web.m-quick.top/">商家后台登陆</a> -->
          <!-- </div> -->
        </div>
        <div class="block_1 flex-col">
          <div class="section_20 flex-row">
            <div class="group_3 flex-col">
              <span class="text_1"></span>
              <div class="text-group_38 flex-col justify-between">
                <span class="text_2">做更好的快递聚合平台</span>
                <span class="text_3">目前已开通国内600+城市50000+网点</span>
              </div>
              <div class="text-wrapper_1 flex-col">
                <span class="text_4"
                  >青岛、北京、上海、广州、深圳设运营中心</span
                >
              </div>
            </div>
          </div>
          <div class="section_21 flex-row">
            <div class="box_1 flex-col"></div>
            <div class="box_2 flex-col"></div>
            <div class="box_3 flex-col"></div>
            <div class="box_4 flex-col"></div>
            <div class="box_5 flex-col"></div>
          </div>
        </div>
        <div class="box_6 flex-col">
          <div id="#ptzs" class="text-wrapper_32 flex-row">
            <span class="text_5">平台展示、小程序介绍</span>
          </div>
          <div class="block_18 flex-row">
            <div class="block_3 flex-col"></div>
          </div>
          <div class="block_19 flex-row justify-between">
            <div class="image-wrapper_1 flex-col">
              <img
                class="image_1"
                referrerpolicy="no-referrer"
                src="../../assets/img/home/1.png"
              />
            </div>
            <div class="box_36 flex-col justify-between">
              <div class="text-group_39 flex-col">
                <span class="text_6"
                  >稻壳聚合快递是一家全国快递聚合寄件平台</span
                >
                <span class="text_7">各大快递一键比价</span>
                <span class="paragraph_1">
                  聚合顺丰、京东、德邦、圆通、申通、中通、韵达、极兔等
                  <br />
                  多家快递公司，便宜寄快递
                </span>
              </div>
              <div class="text-wrapper_3 flex-col">
                <span class="text_8"
                  >一键上门取件，查快递，寄快递，好用又省钱!</span
                >
              </div>
              <div class="block_20 flex-row justify-between">
                <div class="image-text_25 flex-col justify-between">
                  <img
                    class="image_2"
                    referrerpolicy="no-referrer"
                    src="../../assets/img/home/3.png"
                  />
                  <span class="text-group_3">微信扫码体验小程序</span>
                </div>
                <div class="block_21 flex-col justify-between">
                  <img
                    class="image_3"
                    referrerpolicy="no-referrer"
                    src="../../assets/img/home/4.png"
                  />
                  <span class="text_9">稻壳聚合快递公众号</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <span id="#tcjs" class="text_10">同城寄送</span>
        <div class="box_7 flex-col"></div>
        <div class="group_38 flex-row justify-between">
          <div class="section_22 flex-col justify-between">
            <div class="text-group_40 flex-col justify-between">
              <span class="text_11">全国快递聚合寄件平台</span>
              <span class="text_12">各大快递一键比价</span>
              <span class="paragraph_2">
                聚合顺丰、京东、德邦、圆通、申通、中通、韵达、极兔等
                <br />
                多家快递公司，便宜寄快递
              </span>
            </div>
            <div class="text-wrapper_4 flex-col">
              <span class="text_13"
                >一键上门取件，查快递，寄快递，好用又省钱!</span
              >
            </div>
          </div>
          <img
            class="image_4"
            referrerpolicy="no-referrer"
            src="../../assets/img/home/5.png"
          />
        </div>
        <div class="block_5 flex-col">
          <span id="#fgyc" class="text_14">稻壳聚合快递云仓</span>
          <div class="section_2 flex-col"></div>
          <div class="text-group_41 flex-col">
            <span class="text_15">稻壳聚合快递是一家全国快递聚合寄件平台</span>
            <span class="text_16">各大快递一键比价</span>
            <span class="paragraph_3">
              聚合顺丰、京东、德邦、圆通、申通、中通、韵达、极兔等
              <br />
              多家快递公司，便宜寄快递
            </span>
          </div>
          <div class="text-wrapper_5 flex-col">
            <span class="text_17"
              >一键上门取件，查快递，寄快递，好用又省钱!</span
            >
          </div>
        </div>
        <div class="block_6 flex-col">
          <span id="#gyfg" class="text_18">关于稻壳聚合快递</span>
          <div class="group_8 flex-col"></div>
          <span class="text_19">稻壳聚合快递是一家全国快递聚合寄件平台</span>
          <div class="text-wrapper_6">
            <span class="text_20">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
            <span class="paragraph_4">
              稻壳聚合快递是稻壳网络科技公司旗下平台，是中国领先的快递物流信息服务商，公司推行智慧化比价+数字化运营+智能化下单，为消费者提供便捷、优惠的快递寄件服务。
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;稻壳聚合快递与全国多家快递企业达成系统对接，信息服务网络覆盖全国大部分省市，适应于个人、公司、电商商户等用户寄件、退换货、得物快递、社区快递、校园快递等场景的快递下单专用平台，可全国上门取件。
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;公司以提供给客户最佳的快递发件体验为导向，始终专注于服务质量的提升，不断满足市场的需求。依托自有技术和网络，有效利用互联网平台，为客户提供优惠的、划算的一站式在线快递服务。
            </span>
          </div>
          <div class="image-wrapper_12 flex-row justify-between">
            <img
              class="image_5"
              referrerpolicy="no-referrer"
              src="../../assets/img/home/6.png"
            />
            <img
              class="image_20"
              referrerpolicy="no-referrer"
              src="../../assets/img/home/7.png"
            />
          </div>
        </div>
        <div class="group_39 flex-col">
          <div class="group_9 flex-col">
            <div class="text-wrapper_33 flex-row">
              <span class="text_21">快递物流合作伙伴</span>
            </div>
            <div class="group_40 flex-row">
              <div class="box_10 flex-col"></div>
            </div>
            <div class="group_41 flex-row">
              <img
                class="image_7"
                referrerpolicy="no-referrer"
                src="../../assets/img/home/8.png"
              />
              <div class="image-wrapper_13 flex-row">
                <img
                  class="image_8"
                  referrerpolicy="no-referrer"
                  :src="item.lanhuimage0"
                  v-for="(item, index) in loopData0"
                  :key="index"
                />
              </div>
              <img
                class="image_9"
                referrerpolicy="no-referrer"
                src="../../assets/img/home/9.png"
              />
            </div>
            <div class="group_42 flex-row">
              <div class="group_10 flex-row">
                <div class="image-text_26 flex-row justify-between">
                  <span class="text-group_6">查看更多合作伙伴</span>
                  <img
                    class="image_10"
                    referrerpolicy="no-referrer"
                    src="../../assets/img/home/10.png"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="group_11 flex-row">
            <div class="group_43 flex-col justify-between">
              <div class="image-text_27 flex-row justify-between">
                <div class="box_11 flex-col">
                  <div class="box_12 flex-col"></div>
                </div>
                <div class="text-group_42 flex-col justify-between">
                  <span class="text_22">242万+</span>
                  <span class="text_23">四个月完成242万会员的注册</span>
                </div>
              </div>
              <div class="block_11 flex-col">
                <div class="box_13 flex-col"></div>
              </div>
            </div>
            <div class="group_44 flex-row">
              <div class="image-text_28 flex-row justify-between">
                <div class="section_5 flex-col">
                  <div class="box_14 flex-col"></div>
                </div>
                <div class="text-group_43 flex-col justify-between">
                  <span class="text_24">83万+</span>
                  <span class="text_25">四个月完成交易订单量达到83万单</span>
                </div>
              </div>
              <div class="block_12 flex-col">
                <div class="block_13 flex-col"></div>
              </div>
              <div class="block_14 flex-col">
                <div class="section_6 flex-col"></div>
              </div>
            </div>
            <div class="section_7 flex-col">
              <div class="section_8 flex-col"></div>
            </div>
            <div class="group_45 flex-row">
              <div class="image-text_29 flex-row justify-between">
                <div class="box_15 flex-col">
                  <div class="box_16 flex-col"></div>
                </div>
                <div class="text-group_44 flex-col justify-between">
                  <span class="text_26">3.3%</span>
                  <span class="paragraph_5">
                    日用户活跃量平均每100个
                    <br />
                    用户就有3.3单寄件
                  </span>
                </div>
              </div>
              <div class="group_12 flex-col">
                <div class="section_10 flex-col"></div>
              </div>
            </div>
          </div>
          <span id="#hyxw" class="text_27">行业新闻</span>
          <div class="group_13 flex-col"></div>
          <div class="box_37 flex-row">
            <div class="box_38 flex-col justify-between">
              <div class="text-group_45 flex-col justify-between">
                <span class="text_28">10-26</span>
                <span class="text_29">2022</span>
              </div>
              <div class="text-group_46 flex-col justify-between">
                <span class="text_30">11-18</span>
                <span class="text_31">2022</span>
              </div>
              <div class="text-group_47 flex-col justify-between">
                <span class="text_32">02-26</span>
                <span class="text_33">2023</span>
              </div>
            </div>
            <div class="image-text_30 flex-row justify-between">
              <img
                class="image_11"
                referrerpolicy="no-referrer"
                src="../../assets/img/home/11.png"
              />
              <div class="text-group_48 flex-col">
                <span class="text_34"
                  >稻壳聚合快递是一家全国快递聚合寄件平台</span
                >
                <span class="text_35">
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;公司以提供给客户最佳的快递发件体验为导向，始终专注于服务质量的提升，不断满足市场的需求。依托自有技术和网络，有效利用互联网平台，为客户提供优惠的、划算的一站式在线快递服务。
                </span>
                <span class="text_36"
                  >稻壳聚合快递是一家全国快递聚合寄件平台</span
                >
                <span class="text_37">
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;公司以提供给客户最佳的快递发件体验为导向，始终专注于服务质量的提升，不断满足市场的需求。依托自有技术和网络，有效利用互联网平台，为客户提供优惠的、划算的一站式在线快递服务。
                </span>
                <span class="text_38"
                  >稻壳聚合快递是一家全国快递聚合寄件平台</span
                >
                <span class="text_39">
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;公司以提供给客户最佳的快递发件体验为导向，始终专注于服务质量的提升，不断满足市场的需求。依托自有技术和网络，有效利用互联网平台，为客户提供优惠的、划算的一站式在线快递服务。
                </span>
              </div>
            </div>
            <div class="image-wrapper_14 flex-col align-center">
              <img
                class="image_21"
                referrerpolicy="no-referrer"
                src="../../assets/img/home/12.png"
              />
            </div>
          </div>
          <span class="text_40">查看更多&gt;&gt;&gt;</span>
          <div class="group_15 flex-col"></div>
          <span id="#cstd" class="text_41">稻壳聚合快递联合创始人团队</span>
          <div class="group_16 flex-col"></div>
          <div class="image-wrapper_15 flex-row justify-between">
            <img
              class="image_13"
              referrerpolicy="no-referrer"
              src="../../assets/img/home/13.png"
            />
            <img
              class="image_14"
              referrerpolicy="no-referrer"
              src="../../assets/img/home/14.png"
            />
            <img
              class="image_15"
              referrerpolicy="no-referrer"
              src="../../assets/img/home/15.png"
            />
            <img
              class="image_16"
              referrerpolicy="no-referrer"
              src="../../assets/img/home/16.png"
            />
          </div>
          <div class="text-wrapper_34 flex-row">
            <span class="text_42">王贤彬</span>
            <span class="text_43">刘畅</span>
            <span class="text_44">高震</span>
            <span class="text_45">李志红</span>
          </div>
          <div class="box_39 flex-row justify-between">
            <div class="group_18 flex-col"></div>
            <div class="group_19 flex-col"></div>
            <div class="group_20 flex-col"></div>
            <div class="group_21 flex-col"></div>
          </div>
          <div class="text-wrapper_35 flex-row">
            <span class="text_46">稻壳聚合快递创始人/CEO</span>
            <span class="text_47">稻壳聚合快递联合创始人/COO</span>
            <span class="text_48">稻壳聚合快递联合创始人/CTO</span>
            <span class="text_49">稻壳聚合快递联合创始人/CFO</span>
          </div>
          <span class="text_50">来吧！我们一起共事！</span>
          <div class="group_22 flex-col"></div>
          <div class="box_40 flex-row">
            <div class="text-wrapper_36 flex-col justify-between">
              <span class="text_51">了解我们</span>
              <span class="paragraph_6">
                关于稻壳聚合快递
                <br />
                稻壳聚合快递云仓
                <br />
                新闻资讯
                <br />
                隐私政策
                <br />
                博客文章
              </span>
            </div>
            <div class="text-wrapper_37 flex-col justify-between">
              <span class="text_52">关注我们</span>
              <span class="paragraph_7">
                微信公众号
                <br />
                新浪微博
              </span>
            </div>
            <div class="text-wrapper_38 flex-col justify-between">
              <span class="text_53">联系我们</span>
              <span class="paragraph_8">
                联系方式
                <br />
                加入我们
                <br />
                帮助文档
              </span>
            </div>
            <div class="text-wrapper_39 flex-col justify-between">
              <span class="text_54">快递通道</span>
              <span class="paragraph_9">
                稻壳聚合快递驿站申请
                <br />
                稻壳聚合快递军团申请
              </span>
            </div>
            <div class="image-text_31 flex-col justify-between">
              <img
                class="image_17"
                referrerpolicy="no-referrer"
                src="../../assets/img/home/17.png"
              />
              <span class="text-group_14">微信扫码体验小程序</span>
            </div>
            <div class="image-text_32 flex-col justify-between">
              <img
                class="image_18"
                referrerpolicy="no-referrer"
                src="../../assets/img/home/18.png"
              />
              <span class="text-group_15">稻壳聚合快递公众号</span>
            </div>
          </div>
        </div>
        <div class="text-wrapper_14 flex-col">
          <span class="text_55"
            >青岛稻壳网络科技有限公司&nbsp;&nbsp;版权所有&nbsp;鲁ICP备2022025838号-1</span
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      loopData0: [
        {
          lanhuimage0:
            "https://lanhu.oss-cn-beijing.aliyuncs.com/psp7xssnvcfja5r7m6k50vsuhnbhh03c28pd580f518-6b96-43c4-942b-524e055e6582",
        },
        {
          lanhuimage0:
            "https://lanhu.oss-cn-beijing.aliyuncs.com/psyenngbfeqkt7ng4a168jrs14oa0fkh8tn393f6e1b-87a6-4619-856a-23a1f2b5dc2b",
        },
        {
          lanhuimage0:
            "https://lanhu.oss-cn-beijing.aliyuncs.com/pst5lxug55lo8uhs7h3r5qli2fdyqqnpuwj1796e733-468e-473d-b496-8eee71c8ee15",
        },
        {
          lanhuimage0:
            "https://lanhu.oss-cn-beijing.aliyuncs.com/pslvpv5ipr11qm1wraljcsbbq2j47ha7o67adbed4a-6bcc-42a8-97dd-3ae88217f515",
        },
        {
          lanhuimage0:
            "https://lanhu.oss-cn-beijing.aliyuncs.com/pshjnvgvvr7er9z4od2o6grf4phe1sg77765141414-257f-4489-8699-252fa18b1a0e",
        },
        {
          lanhuimage0:
            "https://lanhu.oss-cn-beijing.aliyuncs.com/psopa02y2wh2l6xpm9t3w8wlszq6sir6lbq680a61d4-40c6-4954-83b8-e8c2e23e9ffe",
        },
        {
          lanhuimage0:
            "https://lanhu.oss-cn-beijing.aliyuncs.com/pshoks11v6ot90scydvrym9uezu1oztkm37b38c7d70-bbd9-4340-9ea4-b13b7e82d70b",
        },
        {
          lanhuimage0:
            "https://lanhu.oss-cn-beijing.aliyuncs.com/pstqd27b1yhhp2y20h1qunnmdketay3yr3kc4db2f58-0edc-4b82-b81a-7e87b13c8b65",
        },
      ],
      constants: {},
    };
  },
  methods: {
    goPositon(selector) {
      document.getElementById(selector).scrollIntoView({
        // 不想要滚动动画,则设置为"instant"
        behavior: "smooth", // 滚动动画
        block: "center",
      });
    },
    login() {
      window.location.href = "http://vip-web.m-quick.top/";
    },
  },
};
</script>
<style scoped lang="css" src="../../assets/index.rem.css" />